<template>
    <!-- <div class="layout"> -->
        <!-- <div class="layout-menu">
			
            <el-menu
                :default-active="defaultActive"
                background-color="#001529"
                text-color="#fff"
                active-text-color="#1890ff"
                :collapse="true">
                <el-menu-item index="1" @click="$router.push('/')">
                    <i class="el-icon-document"></i>
                    <span slot="title">3D打托演示程序</span>
                </el-menu-item>
            </el-menu>
        </div> -->
       <!-- <router-view class="layout-content"/>
    </div> -->
	<router-view class="layout-content"/>
</template>

<script>
export default {
    name: "Layout",
    data() {
        return {
            defaultActive: "1",
        }
    },
    mounted() {
        this.$nextTick(() => {
            const path = window.location.href.match(/\/[A-Za-z]*$/)[0];

            this.defaultActive = path === "/" ? "1" : "2";
        })
    }
}
</script>

<style lang="less" scoped>
    .layout {
        display: flex;
        &-menu {
            position: relative;
            background-color: #001529;
            .fold-icon {
                position: absolute;
                bottom: 0;
                left: 0;
                color: #909399;
                padding: 23px;
                font-size: 18px;
                cursor: pointer;
            }
        }
        &-content {
            width: calc(100vw - 64px);
            height: 100vh;
            background-color: #f9f9f9;
            padding: 15px;
            box-sizing: border-box;
        }
    }

    /deep/ .el-menu { border: none; }
</style>